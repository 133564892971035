

.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

header, footer {
  background-color: #fff;
  color: #fff;
  padding: 10px 10px;
  position: fixed;
  width: 100%;
  animation: colorFlash 2s infinite alternate;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  bottom: 0;
}

nav button {
  margin-left: 30px;
  margin-right: -10px;
  background-color: transparent;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  animation: colorFlash 3s infinite alternate;
  display: inline-block;
  text-align: center;
  line-height: 1;
}

@keyframes colorFlash {
  0% {
    color: red;
    border-color: red;
  }
  50% {
    color: blue;
    border-color: blue;
  }
  100% {
    color: green;
    border-color: green;
  }
}

button {
  margin-left: 30px;
  background-color: transparent;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  animation: colorFlash 3s infinite alternate;
}

.dark-mode {
  background-color: black;
  color: #f8f36f;
}


.carousel-container {
  max-width: 80%;
  margin: 0 auto;
}

.carousel-container .carousel .slide img {
  max-width: 100%;
  height: auto;
}


@media (max-width: 768px) {
}
  .App {
    font-size: 14px;
  }

  .carousel-container {
    max-width: 90%; 
}
  footer a {
    text-decoration: none;
  }

.facebook-buttons {
  position: fixed;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  left: 50%;
  transform: translateX(-50%);
}
