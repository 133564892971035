.swiper-container {
  width: 100%;
  height: 100vh; /* Set the height of the swiper container to viewport 
height */
  display: flex;
  justify-content: center;
  align-items: center; /* Center content vertically */
  overflow-y: auto; /* Enable vertical scrolling */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center; /* Center images vertically */
}

